import { Readable } from 'node:stream';
import { useEffect, useLayoutEffect } from 'react';
import type { NextRouter } from 'next/router';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const SCREENS = {
  '2xs': '320px',
  xs: '375px',
  s: '425px',
  m: '768px',
  l: '1024px',
  xl: '1440px',
  '2xl': '1536px',
};

export const COLORS = {
  cloud: '#ffffff',
  charcoal: '#1C1C1E',
  grayscale: {
    '01': '#F4F4F5',
    '02': '#DDDDE0',
    '03': '#BBBBC1',
    '04': '#8E8E98',
    '05': '#55555B',
    '06': '#2B2B2E',
  },
  cipher: {
    DEFAULT: '#5F5BF7',
    '01': '#DFDEFD',
    '02': '#BFBDFC',
    '03': '#9F9DFA',
    '04': '#7F7CF9',
    '05': '#5F5BF7',
    '06': '#4D49CE',
    '07': '#3A37A4',
    '08': '#28267B',
  },
  electric: {
    DEFAULT: '#D8FB4B',
    '01': '#F7FEDB',
    '02': '#EFFDB7',
    '03': '#E8FD93',
    '04': '#E0FC6F',
    '05': '#D8FB4B',
    '06': '#B2CF3C',
    '07': '#8BA32D',
    '08': '#65761F',
  },
  gold: {
    DEFAULT: '#ECBA08',
    '01': '#FBF1CE',
    '02': '#F7E39C',
    '03': '#F4D66B',
    '04': '#F0C839',
    '05': '#ECBA08',
    '06': '#BD9506',
    '07': '#8E7005',
    '08': '#5E4A03',
  },
  lush: {
    DEFAULT: '#21AAA3',
    '01': '#CBECEB',
    '02': '#A1DCD9',
    '03': '#76CBC7',
    '04': '#4CBBB5',
    '05': '#21AAA3',
    '06': '#1A8882',
    '07': '#146662',
    '08': '#0D4441',
  },
  apple: {
    DEFAULT: '#22AC40',
    '01': '#CDEED4',
    '02': '#A2DDAF',
    '03': '#78CD8A',
    '04': '#4DBC65',
    '05': '#22AC40',
    '06': '#1B8A33',
    '07': '#146726',
    '08': '#0E451A',
  },
  sky: {
    DEFAULT: '#1C9AE1',
    '01': '#D2EBF9',
    '02': '#A4D7F3',
    '03': '#84C6EC',
    '04': '#49AEE7',
    '05': '#1C9AE1',
    '06': '#167BB4',
    '07': '#115C87',
    '08': '#0B3E5A',
  },
  amethyst: {
    DEFAULT: '#C551EE',
    '01': '#F3DCFC',
    '02': '#E8B9F8',
    '03': '#DC97F5',
    '04': '#D174F1',
    '05': '#C551EE',
    '06': '#A141C3',
    '07': '#7D3197',
    '08': '#58226C',
  },
  flame: {
    DEFAULT: '#E66027',
    '01': '#FADFD4',
    '02': '#F5BFA9',
    '03': '#F0A07D',
    '04': '#EB8052',
    '05': '#E66027',
    '06': '#B84D1F',
    '07': '#8A3A17',
    '08': '#5C2610',
  },
};

export function formatWithOxfordComma(
  items: string[],
  conjunction: 'and' | 'or',
): string {
  if (items.length === 0) return '';
  if (items.length === 1) return items[0];
  if (items.length === 2) return `${items[0]} ${conjunction} ${items[1]}`;
  return `${items.slice(0, -1).join(', ')}, ${conjunction} ${items[items.length - 1]}`;
}

export async function readBody(readable: Readable) {
  const chunks = [];
  for await (const chunk of readable) {
    chunks.push(typeof chunk === 'string' ? Buffer.from(chunk) : chunk);
  }
  console.log('Body fully read from request.');
  return Buffer.concat(chunks).toString('utf8');
}

/**
 * Extracts the slug from the Next.js router query object.
 *
 * This function handles cases where the slug can be an array or a single string.
 * It joins the array elements with a '/' if the slug is an array, otherwise, it returns the slug as is.
 *
 * @param {NextRouter['query']} query - The query object from Next.js router.
 * @returns {string} - The extracted slug as a string.
 *
 * @example
 * // Example usage with an array slug
 * const query = { slug: ['about', 'team'] };
 * const slug = extractSlugFromRouter(query);
 * console.log(slug); // Output: 'about/team'
 *
 * @example
 * // Example usage with a single string slug
 * const query = { slug: 'about' };
 * const slug = extractSlugFromRouter(query);
 * console.log(slug); // Output: 'about'
 */

export function extractSlugFromRouter(query: NextRouter['query']) {
  let slug = '';
  if (query.slug && Array.isArray(query.slug)) {
    slug = query.slug.join('/');
  } else if (query.slug) {
    slug = query.slug;
  }

  return slug;
}
