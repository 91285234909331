import { cleanEmail } from './clean-email';

/**
 * Format the userId as a SHA-256 hash of the cleaned email
 * Runs in both Node.js and the browser
 */
export async function emailToUserId(email: string): Promise<string> {
  // Clean the email
  const cleanedEmail = cleanEmail(email);
  // Hash the email with SHA-256
  const hashBuffer = await globalThis.crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(cleanedEmail),
  );
  // Convert the hash to an array buffer
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  // Convert the hash to a hex string
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}
