/**
 * Get a cookie by name in the browser
 * @param name - the cookie's name
 * @returns the cookie's value or null if not found
 */
export function getCookie(name: string): string | undefined {
  // Encode the name to handle special characters
  const encodedName = encodeURIComponent(name) + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(encodedName) === 0) {
      return cookie.substring(encodedName.length, cookie.length);
    }
  }
  return undefined;
}
