import {
  AnalyticsBrowser as AnalyticsBrowserCore,
  type Options,
} from '@segment/analytics-next';

import { formatContext } from '../core/format/format-context';
import { formatIdentify } from '../core/format/format-identify';
import { formatTrack } from '../core/format/format-track';
import { Email, PardotId } from '../core/primitives';
import { TrackingEvent, TrackingEventProperties } from '../core/tracking-plan';
import type { TranscendUserTraits } from '../core/types';

export class BrowserAnalytics {
  public core: AnalyticsBrowserCore;

  constructor() {
    this.core = new AnalyticsBrowserCore();
  }

  /**
   * If we get a Pardot ID, store it in localStorage so we can use it in the future
   */
  public setPardotId(pardotId: number): void {
    localStorage.setItem('pardotId', String(pardotId));
  }

  /**
   * Get the Pardot ID from local storage, if it exists
   */
  public async getPardotId(): Promise<number | undefined> {
    const pardotIdRaw = localStorage.getItem('pardotId');
    if (pardotIdRaw) {
      // Local storage returns a string, so we need to cast it to a number
      const castedPardotId = Number(pardotIdRaw);
      const result = await PardotId.safeParseAsync(castedPardotId);
      if (result.success) {
        return result.data;
      }
    }
    return undefined;
  }

  /**
   * Get the email from local storage, if it exists
   */
  public async getEmail(): Promise<string | undefined> {
    const user = await this.core.user();
    const traits = user.traits();
    const result = await Email.safeParseAsync(traits?.email);
    return result.data;
  }

  public async identify(
    email: string,
    traits?: TranscendUserTraits,
    options?: Omit<Options, 'userId' | 'traits'> & { email: string },
  ): ReturnType<AnalyticsBrowserCore['identify']> {
    // Pardot ID
    if (traits?.pardotId) {
      this.setPardotId(traits.pardotId);
    }
    const pardotId = await this.getPardotId();

    const { formattedUserId, formattedTraits } = await formatIdentify({
      email,
      pardotId,
      traits,
    });

    // Format the options object
    const formattedOptions: Options = {
      ...options,
      // Add integration-specific behaviors
      integrations: {
        /**
         * Identify the prospect to the Pardot pixel
         * @see https://segment.com/docs/connections/destinations/catalog/pardot/#fid-or-id-properties
         */
        ...(pardotId && {
          Pardot: {
            id: pardotId,
          },
        }),
      },
      context: formatContext({
        context: options?.['context'],
        formattedTraits,
      }),
    };

    return this.core.identify(
      formattedUserId,
      formattedTraits,
      formattedOptions,
    );
  }

  public async track<
    T extends TrackingEvent,
    P extends TrackingEventProperties[T],
  >(
    event: T,
    properties?: P,
    options?: Omit<Options, 'userId' | 'traits'> & { email: string },
  ): ReturnType<AnalyticsBrowserCore['track']> {
    const { formattedEvent, formattedProperties } = await formatTrack({
      event,
      properties,
    });

    const inputEmail =
      options?.email ||
      // Check if it's cached in Segment local storage
      (await this.getEmail());

    const { formattedUserId, formattedTraits } = inputEmail
      ? await formatIdentify({ email: inputEmail })
      : { formattedUserId: undefined, formattedTraits: undefined };

    const formattedOptions: Options = {
      ...options,
      ...(formattedUserId && {
        userId: formattedUserId,
      }),
      context: formatContext({
        context: options?.['context'],
        formattedTraits,
      }),
    };

    return this.core.track(
      formattedEvent,
      formattedProperties,
      formattedOptions,
    );
  }

  public async group(
    groupId: string,
    traits?: Record<string, string | null>,
    options?: Options,
  ): ReturnType<AnalyticsBrowserCore['group']> {
    return this.core.group(groupId, traits, options);
  }
}
