import DOMPurify from 'isomorphic-dompurify';
import { phone } from 'phone';
import { z } from 'zod';

/**
 * The version of this contact form schema.
 * The client includes the version in the form body for debugging purposes.
 */
export const CONTACT_FORM_VERSION: string = '3.1.3';

/**
 * Sanitize inputs to prevent XSS
 * @see https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html
 */
const SanitizedString = z.string().transform((value) => {
  return DOMPurify.sanitize(value);
});

/**
 * Company sizes options - used to populate the company size select field
 */
export const CompanySizesOptions = [
  'Under 200 employees',
  '200 to 500 employees',
  '500 to 1000 employees',
  '1000 to 5000 employees',
  'Above 5000 employees',
] as const;

/**
 * Partner Types Options - used to populate the partner type select field
 */
export const PartnerTypesOptions = [
  'Technology partner',
  'Law firm partner',
  'Consulting partner',
] as const;
/**
 * The fields that are part of the contact form itself.
 * This can be used to validate ALL contact forms, even if they omit some of the optional fields.
 */
export const ContactFormFields = z.object({
  /** The submitter's first name */
  firstName: SanitizedString.optional(),

  /** The submitter's last name */
  lastName: SanitizedString.optional(),

  /** The submitter's work email */
  email: z.string().email(),

  /** The submitter's job title */
  title: SanitizedString.optional(),

  /** The submitter's company name */
  company: SanitizedString.optional(),

  /** The company's employee count */
  companySize: z.enum(CompanySizesOptions).optional(),

  /** The submitter's phone number */
  phone: z
    .string()
    .refine((val) => phone(val).isValid, {
      message: 'Must be a valid phone number.',
    })
    .optional(),

  /** The submitter's country of residence */
  country: SanitizedString.optional(),

  /** The submitter's state of residence */
  state: SanitizedString.optional(),

  /** Any text that the submitter sent with this submission */
  message: SanitizedString.optional(),

  /** If this is a partner contacting us */
  partnerType: z.enum(PartnerTypesOptions).optional(),

  /** If this is a partner submitting a referral */
  referralPartner: z
    .object({
      partnerFirstName: SanitizedString,
      partnerLastName: SanitizedString,
      partnerEmail: z.string().email(),
      partnerCompany: SanitizedString,
    })
    .optional(),

  /**
   * The submitter's consent to be contacted. If the user is requesting a demo (i.e. be contacted), this is `true` as implied consent.
   * This is only `false` when we're using this form for another purpose on the marketing website, such as to send swag / unlock content downloads.
   * In such a case, we can also request consent for marketing in that contact form.
   */
  consent: z.union([z.boolean(), z.enum(['true', 'false'])]),
});
export type ContactFormFields = z.infer<typeof ContactFormFields>;

/**
 * Metadata that is included in the form body, but is not part of the form component itself.
 */
export const ContactFormMetadata = z.object({
  /** Which version of the form the client is on */
  formVersion: z
    .string()
    .regex(/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/)
    .optional(),

  /** The reCAPTCHA token (only for use on our marketing website) */
  recaptchaToken: z
    .string()
    .min(50)
    // alphanumeric or underscore or hyphen
    .regex(/^(\w|-)+$/)
    .optional(),

  /** This is set up by the marketing team in Salesforce Pardot to label which contact form is being used */
  pardotCampaignId: z.union([z.number(), z.string().regex(/^\d+$/)]),

  /**
   * Comma and space-separated string, e.g.: `9123, 1231`
   * This is set up by the marketing team in Salesforce Pardot to label which mailing lists the user should be added to
   */
  pardotListIds: z.string().regex(/^(\d+)(,\s*\d+)*$/),

  /** Pardot visitor ID */
  pardotVisitorId: SanitizedString.optional(),

  /** Koala cookie, ko_id */
  koalaId: SanitizedString.optional(),

  /** The user's existing anonymousId from Segment */
  anonymousId: SanitizedString.optional(),

  /** Which channel this traffic is coming from. In `main` this'll likely always be "Admin Dashboard" */
  utm_source: SanitizedString.optional(),

  /** What type of channel this traffic is coming from. */
  utm_medium: SanitizedString.optional(),

  /** Which marketing campaign this is coming from. */
  utm_campaign: SanitizedString.optional(),

  /** A unique ID */
  utm_id: SanitizedString.optional(),

  /** Only relevant for search campaigns (e.g. what google search term they came from) */
  utm_term: SanitizedString.optional(),

  /** Only relevant for content-based campaigns (e.g. what blog post they were on) */
  utm_content: SanitizedString.optional(),
});
export type ContactFormMetadata = z.infer<typeof ContactFormMetadata>;

/**
 * The ultimate payload of the contact form sent via HTTP POST to the contact form handler.
 */
export const ContactFormBody = z.intersection(
  ContactFormFields,
  ContactFormMetadata,
);
export type ContactFormBody = z.infer<typeof ContactFormBody>;
