import type { TranscendUserTraits } from '../types';
import { cleanEmail } from './clean-email';
import { emailToUserId } from './user-id';

export async function formatIdentify({
  email,
  traits,
}: {
  email: string;
  pardotId?: number | undefined;
  traits?: Record<string, string | null> | undefined;
}): Promise<{ formattedUserId: string; formattedTraits: TranscendUserTraits }> {
  // Clean the email
  const formattedEmail = cleanEmail(email);

  // SHA-256 of email
  const formattedUserId = await emailToUserId(formattedEmail);

  // Format the Pardot ID
  const formattedPardotId =
    traits && traits['pardotId'] ? Number(traits['pardotId']) : undefined;

  // Format the traits object
  const formattedTraits: TranscendUserTraits = {
    ...traits,
    email: formattedEmail,
    pardotId: formattedPardotId,
  };

  return { formattedUserId, formattedTraits };
}
