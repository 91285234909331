import {
  CONTACT_FORM_VERSION,
  type ContactFormBody,
  type ContactFormFields,
  type ContactFormMetadata,
} from '@transcend-io/contact-form-schema';
import { BrowserAnalytics } from '@transcend-io/segment/browser';
import { TrackingEvent } from '@transcend-io/segment/core';

import { algoliaInsightsClient, INDEX_MAP } from '../algolia';
import { trackLinkedInConversion } from '../segment/linkedin-insight';
import {
  clearViewedAlgoliaObjectIDs,
  getViewedAlgoliaObjectIDs,
} from '../viewed-algolia-objects';
import type { ContactResponse } from './types';

declare global {
  interface Window {
    /** Helper for DataDog synthetic tests on the contact form */
    _transcendContactFormSuccess?: boolean;
  }
}

interface Options {
  analytics: BrowserAnalytics;
}

export async function submitLead(
  fields: ContactFormFields,
  metadata: ContactFormMetadata,
  { analytics }: Options,
) {
  const contactFormBody: ContactFormBody = {
    ...fields,
    ...metadata,
    formVersion: CONTACT_FORM_VERSION,
  };

  const response = await fetch('https://docs.transcend.io/api/contact', {
    method: 'POST',
    body: JSON.stringify(contactFormBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseBody: ContactResponse = await response.json();
  if ('error' in responseBody) {
    console.error(response.status, responseBody.error);
    return;
  }

  // Manually track the conversion in LinkedIn
  trackLinkedInConversion('Contact Form Submitted');

  /**
   * Helper for DataDog synthetic tests
   * @see https://app.datadoghq.com/synthetics/browser/recorder/ykj-hwd-rbp
   */
  window._transcendContactFormSuccess = true;
  setTimeout(() => {
    // Clean up after 10 seconds
    delete window._transcendContactFormSuccess;
  }, 10_000);

  /**
   * Upload conversion data to Algolia for each Algolia object type
   * This allows for Algolia to re-rank search results.
   *
   * For example, if a user viewed the Salesforce integration, then converted,
   * then Salesforce is more likely to show up in search results later.
   */
  algoliaInsightsClient('getUserToken', {}, (err, userToken) => {
    if (err) {
      console.error(err);
      return;
    }

    for (const [algoliaObjectType, index] of Object.entries(INDEX_MAP)) {
      // Get previously-viewed Algolia objects
      const objectIDs = getViewedAlgoliaObjectIDs({
        algoliaObjectType: algoliaObjectType as keyof typeof INDEX_MAP,
      });

      if (objectIDs.length > 0) {
        // Track the conversion in Algolia
        algoliaInsightsClient('convertedObjectIDs', {
          userToken: userToken ? String(userToken) : undefined,
          eventName: TrackingEvent.Enum['Contact Form Submitted'],
          index,
          objectIDs,
        });
        // Clear the viewed Algolia objects after conversion
        clearViewedAlgoliaObjectIDs({
          algoliaObjectType: algoliaObjectType as keyof typeof INDEX_MAP,
        });
      }
    }
  });

  // Get Pardot ID from API response
  const pardotId =
    typeof responseBody.pardotId === 'number'
      ? responseBody.pardotId
      : undefined;

  // Identify the user in Segment
  analytics.identify(fields.email, { pardotId, email: fields.email });
}
