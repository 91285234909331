import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { BrowserAnalytics } from '@transcend-io/segment/browser';

import { useConsentManager } from '../consent-manager';
import { segmentEnv } from './env';

const analytics = new BrowserAnalytics();
const AnalyticsContext = createContext<BrowserAnalytics>(analytics);

// Fire the initial page view event ASAP. This gets dequeued on analytics.core.load();
if (typeof window !== 'undefined') {
  analytics.core.page();
}

export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const { airgap } = useConsentManager();

  // Load analytics.js
  useEffect(() => {
    // Make sure consent is switched on before attempting the import
    if (airgap) {
      const writeKey = segmentEnv.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
      analytics.core.load({ writeKey });
    }
  }, [airgap]);

  // Create `analytics.page` events
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      analytics.core.page();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

/**
 * Hook for Segment analytics
 */
export const useAnalytics = (): BrowserAnalytics =>
  useContext(AnalyticsContext);
