/** Convert union type to intersection type. Black magic. */
type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (
  x: infer I,
) => void
  ? I
  : never;

/**
 * Helper function to safely extract a value from a unioned type (i.e., an object which could have one of several shapes)
 */
function extractFromUnion<
  U extends Record<string, any>,
  K extends keyof UnionToIntersection<U>,
>(unionedObject: U, possibleKey: K): UnionToIntersection<U>[K] | undefined {
  if (possibleKey in unionedObject) {
    return unionedObject[possibleKey as keyof U];
  }
}

/**
 * Factory which makes a helper function to safely extract a value from a unioned type (i.e., an object which could have one of several shapes)
 * @usage ```ts
 * const getFromUnionedPage = makeExtractFromUnionFunction(page);
 * const description = getFromUnionedPage('description');
 * ```
 */
export function makeExtractFromUnionFunction<U extends Record<string, any>>(
  unionedObject: U,
) {
  return <K extends keyof UnionToIntersection<U>>(possibleKey: K) =>
    extractFromUnion(unionedObject, possibleKey);
}
