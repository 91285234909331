import { z } from 'zod';

/**
 * Field names based on contact form schema here
 * @see https://github.com/transcend-io/contact-form-schema/blob/c517bfbd487df4193b737408392c8f98767d860c/src/index.ts#L14
 *
 * DO NOT CHANGE THESE NAMES
 */
export enum FormTextFieldNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Title = 'title',
  Company = 'company',
  Message = 'message',
}

export const HexColor = z.custom<`#${string}`>((val) => {
  return typeof val === 'string' ? /^#[0-9A-Fa-f]{6}$/.test(val) : false;
});
export type HexColor = z.infer<typeof HexColor>;

export const CssPixels = z.custom<`${number}px`>((val) => {
  return typeof val === 'string' ? /^\d+px$/.test(val) : false;
});
export type CssPixels = z.infer<typeof CssPixels>;
