import type { TranscendContext, TranscendUserTraits } from '../types';

export function formatContext({
  context,
  formattedTraits,
}: {
  context: TranscendContext | undefined;
  /** The result of formatIdentify */
  formattedTraits: TranscendUserTraits | undefined;
}): TranscendContext {
  return {
    ...context,
    traits: {
      ...context?.traits,
      ...formattedTraits,
    },
  };
}
