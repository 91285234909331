import type { MediaProps } from '@/lib/sanity';
import type { SanityMedia } from '@/lib/sanity/queries/media';
import type { SanityVideoLoop } from '@/lib/sanity/queries/videoLoop';
import { Image } from '@/components/Image';
import { VideoLoop } from '@/components/VideoLoop';

type Props = {
  media?: SanityMedia | SanityVideoLoop;
} & MediaProps;

export function Media({ media, ...rest }: Props) {
  if (!media) return null;
  if (media._type === 'image') {
    return <Image image={media} {...rest} />;
  } else if (media._type === 'videoLoop') {
    return <VideoLoop video={media} {...rest} />;
  }
}
