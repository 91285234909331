import { strict } from 'assert';
import algoliasearch from 'algoliasearch';
import aa from 'search-insights';

strict(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID);
strict(process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY);

export const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

export const INDEX_MAP = {
  INTEGRATIONS: 'integration_catalog',
  POSTS: 'posts',
  RESOURCES: 'resources',
} as const;

/**
 * Algolia Insights API
 * @see https://www.algolia.com/doc/api-client/methods/insights/
 */
export const algoliaInsightsClient = aa;
algoliaInsightsClient('init', {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
  useCookie: true,
});
