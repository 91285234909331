import type { MouseEventHandler } from 'react';

import { cn } from '@/lib/utils';
import { Link } from '@/components/Link';

type Props = {
  className?: string;
  children: React.ReactNode;
  href?: string;
  target?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'link' | 'form';
  asDiv?: boolean;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  isBackLink?: boolean;
  type?: 'button' | 'submit' | 'reset';
};

export function Button({
  className,
  children,
  href,
  target,
  variant,
  asDiv,
  disabled,
  isBackLink,
  ...rest
}: Props) {
  const cx = cn(
    className,
    'inline-block font-[440] leading-100 -tracking-1 text-charcoal transition-colors',
    {
      'rounded-10 border-1 border-current px-16 text-13 l:px-16 l:text-14':
        variant !== 'link',
      'py-12 l:py-13': variant !== 'link' && variant !== 'form',
      'hover:bg-charcoal hover:text-cloud':
        !disabled && variant !== 'link' && variant !== 'form',
      'bg-electric text-charcoal': variant === 'primary',
      'bg-cloud text-charcoal': variant === 'secondary',
      'py-17 bg-charcoal text-cloud hover:bg-grayscale-06': variant === 'form',
      'group text-12 l:text-14': variant === 'link',
      'cursor-not-allowed text-grayscale-03 bg-grayscale-01': disabled,
    },
  );

  const childrenWithIcon = (
    <>
      {variant === 'link' && isBackLink ? (
        <svg
          className="mb-1 mr-4 inline-block rotate-180 transition-transform group-hover:-translate-x-4"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M0.513399 4.52674L6.24806 4.52674L3.74267 7.09779C3.54245 7.30326 3.54245 7.64045 3.74267 7.84592C3.79017 7.89477 3.84659 7.93351 3.90869 7.95995C3.9708 7.98639 4.03738 8 4.10462 8C4.17186 8 4.23844 7.98639 4.30054 7.95995C4.36265 7.93351 4.41907 7.89477 4.46656 7.84592L7.84986 4.37396C7.89745 4.32521 7.93521 4.26732 7.96098 4.20358C7.98674 4.13985 8 4.07152 8 4.00252C8 3.93352 7.98674 3.8652 7.96098 3.80146C7.93521 3.73773 7.89745 3.67983 7.84986 3.63109L4.4717 0.153852C4.42417 0.105075 4.36774 0.0663826 4.30564 0.0399846C4.24353 0.0135866 4.17697 -1.66082e-07 4.10975 -1.70048e-07C4.04253 -1.74014e-07 3.97597 0.0135866 3.91387 0.0399846C3.85177 0.0663826 3.79534 0.105075 3.74781 0.153852C3.70028 0.202629 3.66257 0.260536 3.63685 0.324267C3.61112 0.387997 3.59789 0.456303 3.59789 0.525285C3.59789 0.594266 3.61112 0.662572 3.63685 0.726302C3.66257 0.790033 3.70028 0.84794 3.74781 0.896717L6.24806 3.47303L0.513399 3.47303C0.23103 3.47303 7.915e-07 3.71012 7.78834e-07 3.99989C7.66167e-07 4.28966 0.23103 4.52674 0.513399 4.52674Z"
            className="fill-current"
          />
        </svg>
      ) : null}
      {children}
      {variant === 'link' && !isBackLink ? (
        <svg
          className="mb-1 ml-4 inline-block transition-transform group-hover:translate-x-4"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M0.513399 4.52674L6.24806 4.52674L3.74267 7.09779C3.54245 7.30326 3.54245 7.64045 3.74267 7.84592C3.79017 7.89477 3.84659 7.93351 3.90869 7.95995C3.9708 7.98639 4.03738 8 4.10462 8C4.17186 8 4.23844 7.98639 4.30054 7.95995C4.36265 7.93351 4.41907 7.89477 4.46656 7.84592L7.84986 4.37396C7.89745 4.32521 7.93521 4.26732 7.96098 4.20358C7.98674 4.13985 8 4.07152 8 4.00252C8 3.93352 7.98674 3.8652 7.96098 3.80146C7.93521 3.73773 7.89745 3.67983 7.84986 3.63109L4.4717 0.153852C4.42417 0.105075 4.36774 0.0663826 4.30564 0.0399846C4.24353 0.0135866 4.17697 -1.66082e-07 4.10975 -1.70048e-07C4.04253 -1.74014e-07 3.97597 0.0135866 3.91387 0.0399846C3.85177 0.0663826 3.79534 0.105075 3.74781 0.153852C3.70028 0.202629 3.66257 0.260536 3.63685 0.324267C3.61112 0.387997 3.59789 0.456303 3.59789 0.525285C3.59789 0.594266 3.61112 0.662572 3.63685 0.726302C3.66257 0.790033 3.70028 0.84794 3.74781 0.896717L6.24806 3.47303L0.513399 3.47303C0.23103 3.47303 7.915e-07 3.71012 7.78834e-07 3.99989C7.66167e-07 4.28966 0.23103 4.52674 0.513399 4.52674Z"
            className="fill-current"
          />
        </svg>
      ) : null}
    </>
  );

  if (href) {
    if (disabled) {
      return (
        <div className={cx} {...rest}>
          {childrenWithIcon}
        </div>
      );
    } else {
      return (
        <Link className={cx} href={href} target={target} {...rest}>
          {childrenWithIcon}
        </Link>
      );
    }
  }

  if (asDiv) {
    return (
      <div className={cx} {...rest}>
        {childrenWithIcon}
      </div>
    );
  }

  return (
    <button className={cx} {...rest}>
      {childrenWithIcon}
    </button>
  );
}
