import { TrackingEvent, TrackingEventProperties } from '../tracking-plan';

export async function formatTrack<
  T extends TrackingEvent,
  P extends TrackingEventProperties[T],
>({ event, properties }: { event: T; properties?: P | undefined }) {
  const formattedEvent = await TrackingEvent.parseAsync(event);
  const formattedProperties =
    await TrackingEventProperties.shape[event].parseAsync(properties);
  return { formattedEvent, formattedProperties };
}
