import { TrackingEvent } from '@transcend-io/segment/core';

declare global {
  interface Window {
    /** LinkedIn insight tag */
    lintrk?: (name: string, properties: Record<string, any>) => void;
  }
}

/**
 * Conversion IDs set up in LinkedIn conversions.
 * @see https://www.linkedin.com/campaignmanager/accounts/508438235/website-tracking/conversions
 */
const CONVERSION_ID_BY_TRACKING_EVENT = {
  /** @see https://www.linkedin.com/campaignmanager/accounts/508438235/conversions/18320410?csv=false&imported=false&step=1 */
  'Contact Form Submitted': 18320410,
} as const satisfies Partial<Record<TrackingEvent, number>>;

/**
 * Track a LinkedIn Insight event.
 * @see https://www.linkedin.com/campaignmanager/accounts/508438235/website-tracking/conversions
 */
export function trackLinkedInConversion(
  trackingEvent: keyof typeof CONVERSION_ID_BY_TRACKING_EVENT,
) {
  const conversionId = CONVERSION_ID_BY_TRACKING_EVENT[trackingEvent];
  if (typeof window !== 'undefined') {
    if (!window.lintrk) {
      console.warn('LinkedIn Insight tag not found');
    } else {
      window.lintrk('track', { conversion_id: conversionId });
    }
  }
}
