import {
  ElementRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import * as Accordion from '@radix-ui/react-accordion';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useRect } from '@reach/rect';
import FocusTrap from 'focus-trap-react';

import { useNavTheme } from '@/lib/nav-theme';
import type { SanityAnnouncement } from '@/lib/sanity/queries/announcement';
import type { SanityHeader } from '@/lib/sanity/queries/header';
import { SanityPage } from '@/lib/sanity/queries/pageProps';
import { useAnalytics } from '@/lib/segment';
import { useAppSizes } from '@/lib/use-app-sizes';
import { cn } from '@/lib/utils';
import { ChevronDown } from '@/components/ChevronDown';
import { Link } from '@/components/Link';

import { Button } from '../Button';
import { Media } from '../Media';
import { HeaderWrapper } from './HeaderWrapper';

type Props = SanityHeader & {
  announcement: SanityAnnouncement | null;
  menuBarStyle: SanityPage['menuBarStyle'];
};

export function SiteHeader({
  primaryNavigation,
  secondaryNavigation,
  announcement,
  menuBarStyle,
}: Props) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isTransitioning, setTransitioning] = useState(false);
  const { windowWidth } = useAppSizes();
  const headerRef = useRef<ElementRef<'header'>>(null);
  const headerRect = useRect(headerRef);
  const isMenuBarHidden = menuBarStyle === 'hidden';

  const router = useRouter();
  useEffect(() => {
    const handleChange = () => {
      toggleMobileNav(false);
    };

    router.events.on('routeChangeStart', handleChange);
    router.events.on('hashChangeStart', handleChange);

    return () => {
      router.events.off('routeChangeStart', handleChange);
      router.events.off('hashChangeStart', handleChange);
    };
  }, [router]);

  const announcementRef = useRef<ElementRef<'a'>>(null);
  const mobileNavRef = useRef<ElementRef<'div'>>(null);

  const analytics = useAnalytics();

  const toggleMobileNav = useCallback((open?: boolean) => {
    if (open === undefined) {
      setMobileNavOpen((prev) => !prev);
    } else {
      setMobileNavOpen(open);
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isMobileNavOpen);

    const onTransitionStart = () => {
      setTransitioning(true);
    };

    const onTransitionEnd = () => {
      setTransitioning(false);
    };

    mobileNavRef.current?.addEventListener(
      'transitionstart',
      onTransitionStart,
    );
    mobileNavRef.current?.addEventListener('transitionend', onTransitionEnd);

    return () => {
      mobileNavRef.current?.removeEventListener(
        'transitionstart',
        onTransitionStart,
      );
      mobileNavRef.current?.removeEventListener(
        'transitionend',
        onTransitionEnd,
      );
    };
  }, [isMobileNavOpen]);

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (isMobileNavOpen && e.key === 'Escape') {
        toggleMobileNav();
      }
    };

    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  useEffect(() => {
    if (isMobileNavOpen && windowWidth >= 1024) {
      setMobileNavOpen(false);
    }
  }, [isMobileNavOpen, windowWidth]);

  const { theme } = useNavTheme();

  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setIsScrolledDown(window.scrollY > 0);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <FocusTrap
      active={isMobileNavOpen}
      focusTrapOptions={{ escapeDeactivates: false, preventScroll: true }}
    >
      <div className="contents">
        {announcement?.message ? (
          <Link
            id="site-announcement"
            ref={announcementRef}
            href={announcement.link.href}
            target={announcement.link.target}
            onClick={() =>
              analytics.track('Clicked Announcement', {
                message: announcement.message,
                href: announcement.link.href,
              })
            }
            className="pointer-events-auto relative z-20 block px-15 pb-11 pt-10 text-center text-13 font-[440] leading-120 -tracking-1 m:px-25 m:text-14"
            style={{
              background: announcement.background,
              color: announcement.foreground,
            }}
          >
            <div className="mx-auto max-w-[295px] s:max-w-[972px]">
              {announcement.message}
            </div>
          </Link>
        ) : null}

        <HeaderWrapper
          isMobileNavOpen={isMobileNavOpen}
          isScrolledDown={isScrolledDown}
          headerRef={headerRef}
          isMenuBarHidden={isMenuBarHidden}
          className={cn(
            'pointer-events-auto sticky top-0 z-20 h-64 w-full px-7 pt-8 text-12 font-[440] leading-100 -tracking-1 l:h-100 l:px-0 l:pt-0 l:text-14',
            {
              'l:text-cloud': theme === 'dark',
            },
          )}
        >
          <>
            <NavigationMenu.Root
              className="NavigationMenuRoot"
              delayDuration={0}
            >
              <NavigationMenu.List
                className={cn('NavigationMenuList text-14 transition-colors', {
                  'bg-[#E9E9EB]/65': theme === 'light',
                  'bg-grayscale-06/35': theme === 'dark',
                })}
              >
                {primaryNavigation.map((item) => {
                  return (
                    <NavigationMenu.Item
                      key={`${item._key}-desktop`}
                      className="h-full"
                    >
                      {item._type === 'menu' ? (
                        <>
                          <NavigationMenu.Trigger className="NavigationMenuTrigger">
                            <span>{item.title}</span>{' '}
                            <ChevronDown className="ChevronDown" />
                          </NavigationMenu.Trigger>
                          <NavigationMenu.Content className="NavigationMenuContent text-charcoal">
                            <div className="px-40">
                              <div className="flex gap-x-40 py-30">
                                {item.columns.map((column, i) => {
                                  const isLastColumn =
                                    i === item.columns.length - 1;
                                  return (
                                    <Fragment key={`${column._key}-desktop`}>
                                      <div className="flex-auto space-y-30">
                                        {column.sections.map((section) => {
                                          if (section._type === 'linkGroup') {
                                            return (
                                              <div
                                                key={`${section._key}-desktop`}
                                                className={cn(
                                                  'whitespace-nowrap',
                                                  {
                                                    'flex gap-x-30':
                                                      section.icon,
                                                  },
                                                )}
                                              >
                                                <div className="mb-15 space-y-15">
                                                  <h3 className="font-azeret text-10 font-normal uppercase leading-150 tracking-2">
                                                    {section.title}
                                                  </h3>
                                                  {section.icon ? (
                                                    <Media
                                                      media={section.icon}
                                                      className="size-50"
                                                      mediaClassName="object-contain"
                                                      sizes="50px"
                                                    />
                                                  ) : null}
                                                </div>
                                                <ul className="space-y-13">
                                                  {section.items.map((item) => {
                                                    return (
                                                      <li
                                                        key={`${item._key}-desktop`}
                                                      >
                                                        <NavigationMenu.Link
                                                          asChild
                                                        >
                                                          <Link
                                                            href={
                                                              item.link.href
                                                            }
                                                            target={
                                                              item.link.target
                                                            }
                                                            className="group relative block rounded-3 text-14 font-[440] leading-120 -tracking-1 outline-none focus-visible:ring-2 focus-visible:ring-cipher focus-visible:ring-offset-4 focus-visible:ring-offset-cloud"
                                                          >
                                                            <div className="absolute -inset-6 scale-95 rounded-6 bg-grayscale-01 opacity-0 transition group-hover:scale-100 group-hover:opacity-75"></div>
                                                            <div className="relative flex items-center">
                                                              {item.icon ? (
                                                                <Media
                                                                  media={
                                                                    item.icon
                                                                  }
                                                                  className="mr-10 size-35"
                                                                  mediaClassName="object-contain"
                                                                  sizes="35px"
                                                                />
                                                              ) : null}
                                                              {item.link.title}
                                                            </div>
                                                          </Link>
                                                        </NavigationMenu.Link>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </div>
                                            );
                                          } else if (
                                            section._type === 'featuredContent'
                                          ) {
                                            return (
                                              <NavigationMenu.Link
                                                asChild
                                                key={`${section._key}-desktop`}
                                              >
                                                <Link
                                                  className="flex-shrink-1 block w-[275px] space-y-10"
                                                  href={section.href}
                                                >
                                                  <Media
                                                    className="block aspect-[16/9] w-full overflow-hidden rounded-15 border-1 border-solid border-grayscale-03"
                                                    media={
                                                      section.thumbnailImage ??
                                                      section.referenceThumbnailImage
                                                    }
                                                    aspect={false}
                                                    placeholder={true}
                                                    sizes="275px"
                                                  />
                                                  <div className="space-y-5">
                                                    <h3 className="text-18 font-[440] leading-116 -tracking-2">
                                                      {section.headline ??
                                                        section.referenceTitle}
                                                    </h3>

                                                    <p className="text-13 font-light leading-150 tracking-0">
                                                      {section.excerpt ??
                                                        section.referenceExcerpt}
                                                    </p>
                                                  </div>
                                                  {section.cardBadge ? (
                                                    <div className="inline-block rounded-full bg-cipher-01 px-10 pb-2 text-12 font-normal leading-150">
                                                      {section.cardBadge}
                                                    </div>
                                                  ) : null}
                                                </Link>
                                              </NavigationMenu.Link>
                                            );
                                          }
                                        })}
                                      </div>
                                      {!isLastColumn && column.hasDivider ? (
                                        <div className="w-1 flex-shrink-0 bg-grayscale-02" />
                                      ) : null}
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </NavigationMenu.Content>
                        </>
                      ) : (
                        <NavigationMenu.Link asChild>
                          <Link
                            className="NavigationMenuLink"
                            href={item.href}
                            target={item.target}
                          >
                            {item.title}
                          </Link>
                        </NavigationMenu.Link>
                      )}
                    </NavigationMenu.Item>
                  );
                })}
              </NavigationMenu.List>
              <div className="ViewportPosition">
                <NavigationMenu.Viewport className="NavigationMenuViewport" />
              </div>
              <NavigationMenu.Indicator className="NavigationMenuIndicator">
                <div className="Arrow" />
              </NavigationMenu.Indicator>
            </NavigationMenu.Root>

            <div className="right-25 top-1/2 z-1 flex items-center gap-x-20 l:absolute l:-translate-y-1/2 l:gap-x-30">
              <nav
                ref={mobileNavRef}
                className="hidden items-center gap-x-30 text-14 l:flex"
              >
                {secondaryNavigation?.map((item, i) => {
                  const isLast = i === secondaryNavigation.length - 1;
                  const Component = isLast ? Button : Link;
                  return (
                    <Component
                      key={`${item._key}-desktop`}
                      href={item.href}
                      target={item.target}
                      className={cn({
                        'transition-colors hover:text-grayscale-04': !isLast,
                      })}
                      variant="primary"
                    >
                      {item.title}
                    </Component>
                  );
                })}
              </nav>

              <button
                className="relative space-y-5 rounded-2 outline-none before:absolute before:left-1/2 before:top-1/2 before:h-40 before:w-48 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-10 focus-visible:ring-2 focus-visible:ring-cipher focus-visible:ring-offset-4 focus-visible:ring-offset-cloud l:hidden"
                onClick={() => toggleMobileNav()}
              >
                <div
                  className={cn(
                    'relative h-2 w-23 rounded-full bg-charcoal transition-transform',
                    {
                      'translate-y-7 -rotate-45': isMobileNavOpen,
                    },
                  )}
                ></div>
                <div
                  className={cn(
                    'relative h-2 w-23 rounded-full bg-charcoal transition-opacity duration-250',
                    {
                      'opacity-0': isMobileNavOpen,
                    },
                  )}
                ></div>
                <div
                  className={cn(
                    'relative h-2 w-23 rounded-full bg-charcoal transition-transform',
                    {
                      '-translate-y-7 rotate-45': isMobileNavOpen,
                    },
                  )}
                ></div>
              </button>
            </div>
          </>
        </HeaderWrapper>

        {/* Mobile Nav */}
        <div
          className={cn(
            'fixed inset-x-0 bottom-0 z-20 flex-grow overflow-y-auto px-7 pb-7 l:hidden',
            {
              invisible: !isTransitioning && !isMobileNavOpen,
            },
          )}
          style={{
            top: headerRect?.bottom ?? 0,
          }}
        >
          <nav
            ref={mobileNavRef}
            className={cn(
              'origin-top-center rounded-b-15 border-x-1 border-b-1 border-grayscale-02 bg-cloud transition duration-400',
              {
                '-translate-y-full scale-95 opacity-0': !isMobileNavOpen,
                invisible: !isTransitioning && !isMobileNavOpen,
              },
            )}
          >
            <Accordion.Root type="multiple">
              {primaryNavigation.map((item) => {
                if (item._type === 'menu') {
                  return (
                    <Accordion.Item
                      value={item._key}
                      key={`${item._key}-mobile`}
                    >
                      <Accordion.Header className="sticky top-0 z-1 border-b-1 border-grayscale-02">
                        <Accordion.Trigger className="MobileNavTrigger">
                          <span>{item.title}</span>
                          <div className="flex w-23 justify-center">
                            <ChevronDown className="MobileChevronDown" />
                          </div>
                        </Accordion.Trigger>
                      </Accordion.Header>
                      <Accordion.Content className="space-y-30 overflow-hidden border-b-1 border-grayscale-02 px-20 pb-25 pt-20">
                        {item.columns.map((column) => {
                          return (
                            <Fragment key={`${column._key}-mobile`}>
                              {column.sections.map((section) => {
                                if (section._type === 'linkGroup') {
                                  return (
                                    <div
                                      key={`${section._key}-mobile`}
                                      className={cn('whitespace-nowrap', {
                                        'flex gap-x-30': section.icon,
                                      })}
                                    >
                                      <div className="mb-15 min-w-[100px] space-y-15">
                                        <h3 className="font-azeret text-10 font-normal uppercase leading-150 tracking-2">
                                          {section.title}
                                        </h3>
                                        {section.icon ? (
                                          <Media
                                            media={section.icon}
                                            className="size-50"
                                            mediaClassName="object-contain"
                                            sizes="50px"
                                          />
                                        ) : null}
                                      </div>
                                      <ul className="space-y-13">
                                        {section.items.map((item) => {
                                          return (
                                            <li key={`${item._key}-mobile`}>
                                              <Link
                                                href={item.link.href}
                                                target={item.link.target}
                                                className="flex items-center rounded-3 text-14 font-[440] leading-120 -tracking-1 outline-none focus-visible:ring-2 focus-visible:ring-cipher focus-visible:ring-offset-4 focus-visible:ring-offset-cloud"
                                              >
                                                {item.icon ? (
                                                  <Media
                                                    media={item.icon}
                                                    className="mr-10 size-35"
                                                    mediaClassName="object-contain"
                                                    sizes="35px"
                                                  />
                                                ) : null}
                                                {item.link.title}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  );
                                } else if (
                                  section._type === 'featuredContent'
                                ) {
                                  return (
                                    <Link
                                      className="block space-y-10"
                                      href={section.href}
                                      key={`${section._key}-mobile`}
                                    >
                                      <Media
                                        className="block aspect-[16/9] w-full rounded-15 border-1 border-solid border-charcoal"
                                        media={
                                          section.thumbnailImage ??
                                          section.referenceThumbnailImage
                                        }
                                        aspect={false}
                                        placeholder={true}
                                        sizes="275px"
                                      />
                                      <div className="space-y-5">
                                        <h3 className="text-18 font-[440] leading-116 -tracking-2">
                                          {section.headline ??
                                            section.referenceTitle}
                                        </h3>

                                        <p className="text-13 font-light leading-150 tracking-0">
                                          {section.excerpt ??
                                            section.referenceExcerpt}
                                        </p>
                                      </div>
                                      {/* {section.cardBadge ? (
                                          <div className="inline-block rounded-full bg-cipher-01 px-10 pb-2 text-12 font-normal leading-150">
                                            {section.cardBadge}
                                          </div>
                                        ) : null} */}
                                    </Link>
                                  );
                                }
                              })}
                              {column.hasDivider ? (
                                <div className="my-20 h-1 w-full bg-grayscale-02"></div>
                              ) : null}
                            </Fragment>
                          );
                        })}
                      </Accordion.Content>
                    </Accordion.Item>
                  );
                } else {
                  return (
                    <Link
                      key={`${item._key}-mobile`}
                      href={item.href}
                      target={item.target}
                    >
                      {item.title}
                    </Link>
                  );
                }
              })}
            </Accordion.Root>

            <div className="divide-y-1 divide-grayscale-02">
              {secondaryNavigation.map((item, i) => {
                const isLast = i === secondaryNavigation.length - 1;

                if (isLast) {
                  return (
                    <div
                      key={`${item._key}-mobile`}
                      className="border-t-1 border-t-grayscale-02 px-20 pb-20 pt-25"
                    >
                      <Link
                        href={item.href}
                        target={item.target}
                        className="block rounded-15 bg-charcoal p-15 text-center text-22 font-[440] leading-100 -tracking-1 text-cloud"
                      >
                        {item.title}
                      </Link>
                    </div>
                  );
                }

                return (
                  <Link
                    key={`${item._key}-mobile`}
                    href={item.href}
                    target={item.target}
                    className="MobileNavLink"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </nav>
        </div>
      </div>
    </FocusTrap>
  );
}
