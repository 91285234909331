import type { SVGProps } from 'react';

export function ChevronDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 9 5" {...props}>
      <path
        className="fill-current"
        fillRule="evenodd"
        d="M4.784 4.884a.407.407 0 0 1-.568 0L.118.86a.39.39 0 0 1 0-.558l.19-.186a.407.407 0 0 1 .568 0L4.5 3.675 8.124.115a.407.407 0 0 1 .569 0l.19.187a.39.39 0 0 1 0 .558z"
        clipRule="evenodd"
      />
    </svg>
  );
}
