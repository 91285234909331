/**
 * To improve Algolia search ranking, track which Algolia objects were viewed by a user
 * If the user converts, Algolia will add a point to this object and re-rank search results accordingly
 */
import { z } from 'zod';

import { INDEX_MAP } from './algolia';

type AlgoliaObjectType = keyof typeof INDEX_MAP;
interface AlgoliaObjectTypeProps {
  algoliaObjectType: AlgoliaObjectType;
}

/**
 * Local storage key for the viewed objects
 * We store the viewed objects in local storage to re-rank Algolia search results
 */
const LOCAL_STORAGE_KEYS: { [key in AlgoliaObjectType]: string } = {
  INTEGRATIONS: 'transcend.algolia.viewedIntegrations',
  POSTS: 'transcend.algolia.viewedPosts',
  RESOURCES: 'transcend.algolia.viewedResources',
};

const RECORD_EXPIRY_TIME = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

const ViewedObjects = z.record(
  z.string().min(1),
  z.object({
    viewedAt: z.coerce.date(),
  }),
);
type ViewedObjects = z.infer<typeof ViewedObjects>;

/**
 * Deserialize the viewed objects object from a string
 */
function deserializeViewedObjects(
  serializedViewedObjects: string,
): ViewedObjects {
  const json = JSON.parse(serializedViewedObjects);
  return ViewedObjects.parse(json);
}

/**
 * Get the existing viewed objects object from local storage, if any, else return empty object
 * Filters out any objects that were viewed more than `RECORD_EXPIRY_TIME` ago
 */
function getViewedObjects({
  algoliaObjectType,
}: AlgoliaObjectTypeProps): ViewedObjects {
  const localStorageKey = LOCAL_STORAGE_KEYS[algoliaObjectType];

  // Get existing viewed objects from local storage, if any
  const serializedViewedObjects = localStorage.getItem(localStorageKey);

  // Nothing in local storage yet. Initialize empty object
  if (serializedViewedObjects === null) {
    return {};
  }

  try {
    const allViewedObjects = deserializeViewedObjects(serializedViewedObjects);
    // Filter out any objects that were viewed more than 30 days ago
    const recentlyViewedObjects = Object.fromEntries(
      Object.entries(allViewedObjects).filter(
        ([, { viewedAt }]) =>
          Date.now() - viewedAt.getTime() < RECORD_EXPIRY_TIME,
      ),
    );
    return recentlyViewedObjects;
  } catch (error) {
    console.error(
      `Error deserializing viewed objects for Algolia object type ${algoliaObjectType}: ${error}`,
    );
    // Reset localStorage
    clearViewedAlgoliaObjectIDs({ algoliaObjectType });
    return {};
  }
}

/**
 * Serialize the viewed objects object to a string
 */
function serializeViewedObjects(viewedObjects: ViewedObjects) {
  return JSON.stringify(viewedObjects);
}

/**
 * Overwrite the viewed objects object in local storage
 */
function setViewedObjects({
  viewedObjects,
  algoliaObjectType,
}: {
  viewedObjects: ViewedObjects;
} & AlgoliaObjectTypeProps): void {
  const localStorageKey = LOCAL_STORAGE_KEYS[algoliaObjectType];
  const serializedViewedObjects = serializeViewedObjects(viewedObjects);
  localStorage.setItem(localStorageKey, serializedViewedObjects);
}

/**
 * Add a viewed object to the viewed objects object in local storage
 */
export function addViewedAlgoliaObjectID({
  objectID,
  algoliaObjectType,
}: { objectID: string } & AlgoliaObjectTypeProps): void {
  const viewedObjects = getViewedObjects({ algoliaObjectType });
  // Add the object slug to the viewed objects object. If it exists, update the viewedAt timestamp
  viewedObjects[objectID] = { viewedAt: new Date() };
  // Validate updated object
  ViewedObjects.parse(viewedObjects);
  setViewedObjects({ viewedObjects, algoliaObjectType });
}

/**
 * Get a list of Algolia objectIDs that were viewed
 */
export function getViewedAlgoliaObjectIDs({
  algoliaObjectType,
}: AlgoliaObjectTypeProps): string[] {
  return Object.keys(getViewedObjects({ algoliaObjectType }));
}

/**
 * Clear the viewed objects object in local storage
 */
export function clearViewedAlgoliaObjectIDs({
  algoliaObjectType,
}: AlgoliaObjectTypeProps): void {
  setViewedObjects({ viewedObjects: {}, algoliaObjectType });
}
