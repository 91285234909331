import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const segmentEnv = createEnv({
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get typeerrors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    /**
     * The write key for Segment (dev https://app.segment.com/transcend/sources/transcend_io_development/overview)
     */
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string(),
  },
  runtimeEnv: {
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  },
});
