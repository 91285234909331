import { z, type ZodObject } from 'zod';

import { ContactFormTrackingEventProperties } from './primitives';

/**
 * Segment recommends event names built from a noun and past-tense verb
 * @see https://segment.com/docs/connections/spec/track/#event
 *
 * DO NOT CHANGE THESE NAMES
 */
export const TRACKING_EVENT_NAMES = [
  /** When someone submits the contact form */
  'Contact Form Submitted',
  /** When someone subscribes to a newsletter */
  'Subscribed to Newsletter',
  /** When someone submits a community application */
  'Submitted Community Application',
  /** When a partner uses the referral form */
  'Partner Submitted Referral',
  /** When a user touches a cost calculator */
  'Used Calculator',
  // When a user clicks the announcement banner
  'Clicked Announcement',
  // When a user views a product
  'Viewed Product',
  // When a user views a resource
  'Viewed Resource',
  // When a user views a blog post
  'Viewed Blog Post',
  // When a user views an integration
  'Viewed Integration',
  // When a user selects a search result
  'Search Result Selected',
  // When a prospect or customer books a meeting
  'Meeting Booked',
] as const;
export const TrackingEvent = z.enum(TRACKING_EVENT_NAMES);
export type TrackingEvent = z.infer<typeof TrackingEvent>;

export const TrackingEventProperties = z.object({
  [TrackingEvent.enum['Contact Form Submitted']]:
    ContactFormTrackingEventProperties,
  [TrackingEvent.enum['Subscribed to Newsletter']]:
    ContactFormTrackingEventProperties,
  [TrackingEvent.enum['Submitted Community Application']]:
    ContactFormTrackingEventProperties,
  [TrackingEvent.enum['Partner Submitted Referral']]:
    ContactFormTrackingEventProperties,
  [TrackingEvent.enum['Used Calculator']]: z.object({
    resourceTitle: z.string(),
    resourceId: z.string(),
  }),
  [TrackingEvent.enum['Clicked Announcement']]: z.object({
    message: z.string(),
    href: z.string(),
  }),
  [TrackingEvent.enum['Viewed Product']]: z.object({
    productName: z.string(),
  }),
  [TrackingEvent.enum['Viewed Resource']]: z.object({
    resourceTitle: z.string(),
    resourceId: z.string(),
  }),
  [TrackingEvent.enum['Viewed Blog Post']]: z.object({
    blogPostTitle: z.string(),
    blogPostCategories: z.array(z.string()),
    blogPostId: z.string(),
  }),
  [TrackingEvent.enum['Viewed Integration']]: z.object({
    integrationName: z.string(),
    integrationSlug: z.string(),
    integrationId: z.string(),
  }),
  [TrackingEvent.enum['Search Result Selected']]: z.object({
    /** The Algolia index name for this search */
    algoliaIndexName: z.string().min(1),
    /** The path slug of the hit */
    hitSlug: z.string().min(1),
    /** The title of the hit */
    hitTitle: z.string().min(1),
    /** The Algolia object ID */
    hitObjectID: z.string().min(1),
    /** The types of search results */
    hitType: z.string().min(1),
  }),
  [TrackingEvent.enum['Meeting Booked']]: z.object({
    inviteeEmail: z.string(),
    inviteeName: z.string(),
    inviteeCompany: z.string().optional(),
    eventName: z.string(),
    eventType: z.string(),
    eventDescription: z.string().optional(),
    eventAssignedToEmail: z.string(),
    eventStartTime: z.string().datetime(),
    eventID: z.string(),
    source: z.enum(['Chili Piper', 'Calendly']),
  }),
}) satisfies ZodObject<Record<keyof typeof TrackingEvent.enum, any>>;
export type TrackingEventProperties = z.infer<typeof TrackingEventProperties>;
