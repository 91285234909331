import { useEffect, useState } from 'react';
import { useRect } from '@darkroom.engineering/hamo';

export function useAppSizes() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const [setRect] = useRect({
    debounce: 0,
    callback: (rect: DOMRect) => {
      setHeaderHeight(rect.height);
      document.documentElement.style.setProperty(
        '--header-height',
        `${rect.height}px`,
      );
    },
  });

  useEffect(() => {
    if (document === undefined) return;
    const headerElement = document.querySelector('#site-header');
    if (headerElement) setRect(headerElement);
  }, [setRect]);

  useEffect(() => {
    if (window === undefined) return;

    const resize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  return { windowWidth, windowHeight, headerHeight };
}
