import { ElementRef, useEffect, useRef } from 'react';
import { useIntersection } from 'use-intersection';

import { MediaProps } from '@/lib/sanity';
import { SanityVideoLoop } from '@/lib/sanity/queries/videoLoop';
import { cn } from '@/lib/utils';

type Props = {
  video: SanityVideoLoop;
} & MediaProps;

export function VideoLoop({
  video,
  className,
  mediaClassName,
  aspect = true,
  style,
}: Props) {
  const ref = useRef<ElementRef<'video'>>(null);
  const intersecting = useIntersection(ref, { threshold: 0.01 });

  useEffect(() => {
    if (intersecting) {
      ref.current?.play();
    } else {
      ref.current?.pause();
    }
  }, [intersecting]);

  return (
    <>
      <div
        className={cn('relative overflow-clip', className)}
        style={{
          aspectRatio: aspect ? `${video.width} / ${video.height}` : undefined,
          ...style,
        }}
      >
        <video
          ref={ref}
          className={cn(
            'absolute inset-0 size-full object-cover transition-opacity duration-500',
            mediaClassName,
          )}
          src={`${video.url}#t=0.1`}
          preload="metadata"
          loop
          muted
          playsInline
        ></video>
      </div>
    </>
  );
}
