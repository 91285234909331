import { ContactFormFields } from '@transcend-io/contact-form-schema';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import type { SanityForm } from '@/lib/sanity/queries/form';
import { cn } from '@/lib/utils';

import { Form, FormStatus } from './Form';

type Props = {
  emailInputPlaceholder: string;
  submitButtonLabel: SanityForm['submitButtonLabel'];
  pardotCampaignId: SanityForm['pardotCampaignId'];
  pardotListIds: SanityForm['pardotListIds'];
  trackingEventName: SanityForm['trackingEventName'];
  confirmation: string;
  className?: string;
  variant: 'hero' | 'footer';
};

export function FormEmailCapture({
  emailInputPlaceholder,
  submitButtonLabel,
  trackingEventName,
  pardotCampaignId,
  pardotListIds,
  confirmation,
  className,
  variant,
}: Props) {
  return (
    <div className={className}>
      <Form
        trackingEventName={trackingEventName}
        pardotCampaignId={pardotCampaignId}
        formFieldsSchema={ContactFormFields}
        pardotListIds={pardotListIds}
        confirmationComponent={<p className="h-42">{confirmation}</p>}
      >
        {(status, { register }) => (
          <>
            <input
              {...register('email')}
              type="email"
              className={clsx(
                'block w-full rounded-10 bg-cloud text-16 font-normal leading-100 !text-charcoal outline-none placeholder:text-grayscale-04 focus:ring-2 focus:ring-cipher m:text-14',
                {
                  'px-14 py-13': variant === 'footer',
                  'px-20 py-18': variant === 'hero',
                },
              )}
              placeholder={emailInputPlaceholder}
            />
            <button
              className={clsx(
                'absolute inset-y-0 right-0 rounded-10 bg-electric text-charcoal outline-none focus:ring-2 focus:ring-cipher',
                {
                  'px-17 py-12': variant === 'footer',
                  'px-20 py-15': variant === 'hero',
                },
              )}
              type="submit"
            >
              <AnimatePresence mode="popLayout">
                {status === FormStatus.Pending ? (
                  <motion.div
                    key="spinner"
                    className={cn(
                      'mx-auto animate-spin rounded-full border-2 border-charcoal border-r-transparent',
                      {
                        'size-16': variant === 'footer',
                        'size-20': variant === 'hero',
                      },
                    )}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <p className="sr-only">Submitting form…</p>
                  </motion.div>
                ) : (
                  <motion.div
                    key="label"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <span className="sr-only">{submitButtonLabel}</span>
                    <svg
                      className={cn({
                        'size-16': variant === 'footer',
                        'size-20': variant === 'hero',
                      })}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M1.0268 9.05349L12.4961 9.05349L7.48535 14.1956C7.0849 14.6065 7.0849 15.2809 7.48535 15.6918C7.58034 15.7895 7.69317 15.867 7.81739 15.9199C7.9416 15.9728 8.07476 16 8.20924 16C8.34372 16 8.47687 15.9728 8.60109 15.9199C8.7253 15.867 8.83814 15.7895 8.93313 15.6918L15.6997 8.74791C15.7949 8.65043 15.8704 8.53464 15.922 8.40717C15.9735 8.2797 16 8.14305 16 8.00505C16 7.86704 15.9735 7.7304 15.922 7.60293C15.8704 7.47546 15.7949 7.35966 15.6997 7.26218L8.9434 0.307704C8.84834 0.21015 8.73548 0.132765 8.61127 0.0799692C8.48707 0.0271732 8.35395 -3.32164e-07 8.21951 -3.40096e-07C8.08507 -3.48028e-07 7.95195 0.0271731 7.82774 0.0799692C7.70353 0.132765 7.59068 0.21015 7.49562 0.307704C7.40055 0.405258 7.32514 0.521072 7.2737 0.648533C7.22225 0.775994 7.19577 0.912606 7.19577 1.05057C7.19577 1.18853 7.22225 1.32514 7.2737 1.4526C7.32514 1.58007 7.40055 1.69588 7.49562 1.79343L12.4961 6.94607L1.0268 6.94607C0.46206 6.94607 1.13984e-06 7.42024 1.11451e-06 7.99978C1.08918e-06 8.57932 0.462059 9.05349 1.0268 9.05349Z"
                        className="fill-current"
                      />
                    </svg>
                  </motion.div>
                )}
              </AnimatePresence>
            </button>
          </>
        )}
      </Form>
    </div>
  );
}
