import { PropsWithChildren, RefObject } from 'react';

import { cn } from '@/lib/utils';
import { Link } from '@/components/Link';

import { TranscendLogo } from '../TranscendLogo';

/**
 *
 * Conditionally render a link component or a div component based on the value of `isMenuBarHidden`.
 * This is used to hide the logo and menu bar when the menu bar is hidden.
 *
 * @param className - The class name to apply to the wrapper element.
 * @param isMenuBarHidden - Whether the menu bar is hidden.
 * @param children - The content to render inside the wrapper.
 * @returns A div element if `isMenuBarHidden` is true, otherwise a link element linking to the home page.
 */
const LogoWrapper: React.FC<
  PropsWithChildren<{ isMenuBarHidden: boolean; className: string }>
> = ({ className, isMenuBarHidden, children }) => {
  if (isMenuBarHidden) {
    return <div className={className}>{children}</div>;
  } else {
    return (
      <Link className={className} href="/">
        {children}
      </Link>
    );
  }
};

/**
 * The header wrapper component is responsible for rendering the header and conditionally rendering its contents.
 * It takes in various props, such as `isScrolledDown`, `headerRef`, `isMenuBarHidden`, and `isMobileNavOpen`.
 * The component conditionally renders a div or a link based on the value of `isMenuBarHidden`.
 * The div element is used to hide the logo and menu bar when the menu bar is hidden.
 * The link element is used to link to the home page when the menu bar is visible.
 *
 * @param className - The class name to apply to the header wrapper element.
 * @param isScrolledDown - Whether the user has scrolled down on the page.
 * @param headerRef - A reference to the header element.
 * @param isMenuBarHidden - Whether the menu bar is hidden.
 * @param isMobileNavOpen - Whether the mobile navigation menu is open.
 * @returns The header wrapper component with the appropriate content based on the props.
 */
export const HeaderWrapper: React.FC<
  PropsWithChildren<{
    className?: string;
    isScrolledDown: boolean;
    headerRef: RefObject<HTMLElement>;
    isMenuBarHidden: boolean;
    isMobileNavOpen: boolean;
  }>
> = ({
  children,
  isScrolledDown,
  headerRef,
  isMenuBarHidden,
  className,
  isMobileNavOpen,
}) => {
  return (
    <header id="site-header" ref={headerRef} className={className}>
      <div
        className={cn(
          'flex h-56 items-center justify-between rounded-15 border-1 border-grayscale-02 bg-cloud px-20 transition-[border-radius] l:h-full l:border-none l:bg-[unset] l:px-0',
          {
            'rounded-b-0': isMobileNavOpen,
          },
        )}
      >
        <LogoWrapper
          isMenuBarHidden={isMenuBarHidden}
          className={cn(
            'left-25 top-1/2 z-1 block transition-opacity l:absolute l:-translate-y-1/2',
            {
              'l:pointer-events-none l:opacity-0': isScrolledDown,
            },
          )}
        >
          <TranscendLogo className="h-30 transition-colors l:h-40" />
        </LogoWrapper>
        <LogoWrapper
          isMenuBarHidden={isMenuBarHidden}
          className={cn(
            'left-25 top-1/2 z-1 hidden transition-opacity l:absolute l:block l:-translate-y-1/2',
            {
              'pointer-events-none opacity-0': !isScrolledDown,
            },
          )}
        >
          <TranscendLogo className="h-30 transition-colors l:h-40" iconOnly />
        </LogoWrapper>

        {isMenuBarHidden ? null : children}
      </div>
    </header>
  );
};
