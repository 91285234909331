import { SVGProps, useEffect, useState } from 'react';

import { useConsentManager } from '@/lib/consent-manager';

export interface YourPrivacyChoicesProps {}

/**
 * CPRA Your Privacy Choices button
 * @see https://cppa.ca.gov/regulations/pdf/20221102_mod_text.pdf
 * @see https://oag.ca.gov/privacy/ccpa/icons-download
 */
export function YourPrivacyChoices() {
  const { transcend, airgap } = useConsentManager();
  const [consentEnabled, setConsentEnabled] = useState<boolean>(false);

  useEffect(() => {
    // Show the 'Your Privacy Choices' button based on `airgap.getRegimes`
    function getConsentEnabled(): boolean {
      if (!airgap) {
        return false;
      }
      const regimes = airgap.getRegimes();

      // We display this button if there are any choices available
      const consentEnabled =
        typeof regimes !== 'undefined' &&
        // There is a regime detected, which is not 'Unknown' ..
        ((regimes.size === 1 && !regimes.has('Unknown')) ||
          // .. Or there are multiple regimes detected
          regimes.size > 1);

      return consentEnabled;
    }
    setConsentEnabled(getConsentEnabled());
  }, [airgap]);

  if (!consentEnabled) {
    return null;
  }

  return (
    <button
      className="mt-32 block"
      onClick={() =>
        transcend?.showConsentManager({ viewState: 'CompleteOptions' })
      }
    >
      <div className="flex items-center">
        <PrivacyChoicesIcon className="h-12 pr-5" />
        <span>Your Privacy Choices</span>
      </div>
    </button>
  );
}

function PrivacyChoicesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 30 14" {...props}>
      <path
        d="M7.4 12.8h6.8l3.1-11.6H7.4C4.2 1.2 1.6 3.8 1.6 7s2.6 5.8 5.8 5.8"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#fff',
        }}
      />
      <path
        d="M22.6 0H7.4c-3.9 0-7 3.1-7 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7m-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.4c-3.2 0-5.8-2.6-5.8-5.8"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#06f',
        }}
      />
      <path
        d="M24.6 4c.2.2.2.6 0 .8L22.5 7l2.2 2.2c.2.2.2.6 0 .8s-.6.2-.8 0l-2.2-2.2-2.2 2.2c-.2.2-.6.2-.8 0s-.2-.6 0-.8L20.8 7l-2.2-2.2c-.2-.2-.2-.6 0-.8s.6-.2.8 0l2.2 2.2L23.8 4c.2-.2.6-.2.8 0"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M12.7 4.1c.2.2.3.6.1.8L8.6 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.4 7.7c-.2-.2-.2-.6 0-.8s.6-.2.8 0L8 8.6l3.8-4.5c.2-.2.6-.2.9 0"
        style={{
          fill: '#06f',
        }}
      />
    </svg>
  );
}
